import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import * as Mui from "@mui/material";
import Panel from '../utils/Panel';
import Banner from './Components/Banner';


import about_1 from'../img/about_1.png';
import about_2 from'../img/about_2.png';
import about_3 from'../img/about_3.png';
import about_4 from'../img/about_4.png';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '20vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: '4rem',
  },
  text: {
    marginTop: theme.spacing(1),
  },
}));


export default function Guide() {
  const classes = useStyles();


  return (
<Mui.Grid container direction="column" spacing={3} >
  <Banner/>
  
  <Mui.Grid item>
      <Panel title={'什么是量化模型？'}>
      <Mui.Stack direction="column" spacing={2} alignItems="center" pb = {3}>

<img  src={about_1} width={window.innerWidth > 400 ? 600 : 300}/>

    <Mui.Typography px={5} variant="body1" component="body1">
          量化模型是指借助现代统计学和数学的方法，利用计算机技术来进行交易的证券投资方式。简单来说，就是开发一套程序大模型，来替代传统人工看盘、读盘、挂单、下单的交易流程。
        </Mui.Typography>
        
        <Mui.Typography px={5} variant="body1" component="body1">
与传统投资者相比，量化模型依赖机器来判断交易策略，不受情绪波动影响，可7X24小时无休止看盘，还能从能搜集到的所有市场数据中总结规律，因此具有降维打击般的优势。
        </Mui.Typography>

        <Mui.Typography px={5} variant="body1" component="body1">
在成熟资本市场中，量化交易是技术类交易的绝对主流。美国股票市场的量化交易量不低于70%，期货期权类投资不低于80%，无脑跟着资本家的选择，是管理个人资产的不二法门。
        </Mui.Typography>
</Mui.Stack>
      </Panel>
  </Mui.Grid>

  <Mui.Grid item>
      <Panel title={'什么是量子+赋能？'}>
      <Mui.Stack direction="column" spacing={2} alignItems="center" pb = {5}>

<img  src={about_2} width={window.innerWidth > 400 ? 600 : 300}/>

    <Mui.Typography px={5} variant="body1" component="body1">
    量子赋能量化交易大模型是必乐量子独创技术，团队成员从量子物理的表征理论中得到灵感，将量子算法引入量化交易，实现量子交易赋能。具体来说，与传统量化交易模型相比，量子赋能算法具有如下显著优势：
        </Mui.Typography>
        
        <Mui.Typography px={5} variant="body1" component="body1">
        一是更高效的交易频率。传统的交易模型受限于交易所成交延迟，只能T+1甚至T+2、T+3才能撮合成交。必乐量子赋能算法引入深度自撮合原理，自己的高频挂单统一为一个虚拟池，新的挂单优先在虚拟池内对抵，自己与自己做对手盘，只向交易所发送最后剩余的净挂单指令。因而可以实现200%-1000%的换手率，大大提高了资金使用效率。
        </Mui.Typography>

        <Mui.Typography px={5} variant="body1" component="body1">
二是更广博的指标检测。传统的交易模型往往只就事论事地盯着交易标的价格走势，从曲线图解上总结规律。必乐量子赋能算法引入指标自纠缠概念，一个模型通盘考虑整个市场2000-5000个有效标的的全部价格走势，更充分地反映经济动态运行规律。
        </Mui.Typography>

        

        <Mui.Typography px={5} variant="body1" component="body1">
三是更充分的市场学习。传统的交易模型多为利用历史数据一次推演训练而得，本质上和刻舟求剑没有什么区别。必乐量子赋能算法引入波动自学习机制，模型自己在交易过程中生成的订单的胜率同样纳入下一步的学习参考，每个参数3-5天内即可自迭代一轮，因而可以快速跟进最反复的市场波动。        
        </Mui.Typography>

        <img  src={about_3} width={window.innerWidth > 400 ? 600 : 300}/>
</Mui.Stack>
      </Panel>
  </Mui.Grid>

  <Mui.Grid item>
      <Panel title={'必乐量子产品线'}>
      <Mui.Stack direction="column" spacing={2} alignItems="center" pb = {5}>

<img  src={about_4} width={window.innerWidth > 400 ? 800 : 300}/>

    <Mui.Typography px={5} variant="body1" component="body1">
    目前必乐量子采用3/30定价，直推的个人用户可以享受2/20的专属优惠，即额外享受原本属于中间商的1/10差价。具体解释如下。
        </Mui.Typography>
        
        <Mui.Typography px={5} variant="body1" component="body1">
        管理费率方面，我们每年按照资金规模收取3%（2%）的资产管理费，用于采购机房、维持算力和保持风控合规，这是保证量子交易赋能先进性的关键所在。
        </Mui.Typography>

        <Mui.Typography px={5} variant="body1" component="body1">
        收益分成方面，对于超过历史新高的高水位收益，70%（80%）的绝对收益归出资方所有，20%（30%）的绝对收益归技术团队所有。技术团队与客户们站在一起，与市场赛跑，向资本夺利，合作共赢是我们坚持的原则。
        </Mui.Typography>

        

        <Mui.Typography px={5} variant="body1" component="body1">
        风险控制方面，我们针对不同风险偏好的客户提出一系列的控制指标，达到最大回撤即强制撤出。但是，我们衷心地希望您能给予我们最大的信心，让必乐量子更好地在曲折中前进，最终实现量子算法和市场交易的全面结合。        
        </Mui.Typography>

</Mui.Stack>
      </Panel>
  </Mui.Grid>
  
</Mui.Grid>
    
    
  );
}

