import { createContext, useCallback, useContext, useEffect, useState, useRef } from 'react'
import * as Mui from "@mui/material";

const MessageContext = createContext()

export const useMessage = () => useContext(MessageContext)

export function MessageProvider(props){
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');
    useEffect(() => {
      if (text != ''){
          setOpen(true);
      }
    }, [text])
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
          return false;
      }
      setOpen(false);
      setText('');
  };

  const message = (t) => {
    setText(t);
  }

    return (
        <MessageContext.Provider value={{message}}>
          {props.children}
          <Mui.Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center',}}
            open={open}
            autoHideDuration={1500}
            onClose={handleClose}
            message={text}
        />
        </MessageContext.Provider>
    )
}