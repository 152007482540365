import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as Mui from '@mui/material';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { grey } from '@mui/material/colors';

export const bottomNavigationHeight = 88
const radius = 16
const primaryColor = '#F8674D'
const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
})

const theme = createTheme({
  components: {
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          backgroundColor: '#fff',
          border: `2px solid ${grey[300]}`
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginLeft: 0
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          minWidth: '0px'
        },
        sizeSmall: {
          padding: '0px 8px'
        },
        sizeMedium: {
          padding: '8px 16px'
        },
      }
    },
    MuiBottomNavigation: {
      defaultProps: {
        showLabels: true
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          minWidth: 50
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'auto'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'underline',
          cursor: 'pointer',
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: primaryColor,
          borderColor: primaryColor
        },
        sizeSmall: {
          padding: '0px 8px'
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        arrow: true
      },
      styleOverrides: {
        tooltip: {
          maxWidth: 200,
        },
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginBottomCenter: {
          [breakpoints.down('sm')]: {
            bottom: bottomNavigationHeight
          }
        },
        anchorOriginBottomRight: {
          [breakpoints.down('sm')]: {
            bottom: bottomNavigationHeight
          }
        },
        anchorOriginBottomLeft: {
          [breakpoints.down('sm')]: {
            bottom: bottomNavigationHeight
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        outlined: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)'
        }
      }
    }
  },
  breakpoints: breakpoints,
  shape: {
    borderRadius: radius
  },
  palette: {
    primary: {
      main: primaryColor,
      contrastText: '#fff',
    },
    red: {
      main: '#EF2C09',
      contrastText: '#fff',
    },
    green: {
      main: '#98CE00',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F8D24C',
      contrastText: '#fff',
    },
    background: {
      default: grey[100]
    }
  },
  typography: {
    fontFamily: ' Tomorrow, Noto Sans SC, Arial',
  },
});

const snackbarCommonStyles = {
  marginTop: 6,
  marginBottom: 6,
  paddingTop: 10,
  paddingBottom: 10,
  maxWidth: 500,
  pointerEvents: 'initial',
  flexWrap: 'nowrap',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: radius
}

export const useSnackbarStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
    ...snackbarCommonStyles
  },
  error: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    ...snackbarCommonStyles
  },
  warning: {
    color: theme.palette.warning.main,
    border: `1px solid ${theme.palette.warning.main}`,
    ...snackbarCommonStyles
  },
  info: {
    color: theme.palette.info.main,
    border: `1px solid ${theme.palette.info.main}`,
    ...snackbarCommonStyles
  },
}));

function Provider(props) {
  return (
    <ThemeProvider theme={theme}>
      <Mui.CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}

export default Provider