import { createContext, useCallback, useContext, useEffect, useState, useRef } from 'react'
import { ServerURL } from '../utils/Config';
import axios from 'axios';

const UserContext = createContext()

export const useUser = () => useContext(UserContext)

export function UserProvider(props){
    const [token, setToken] = useState('');
    const [profile, setProfile] = useState();
    const updateToken = (t) => {
      setToken(t);
    }
    useEffect(() => {
      if (token == '') {
        setProfile(null);
      }
      axios.post(ServerURL+'/user/profile?api=True',{},
        {
          headers: {
            'Authorization': token
          }
        }
      )
      .then(function (response) {
        setProfile(response.data.data);
      });
    }, [token])


    return (
        <UserContext.Provider value={{updateToken, profile, token}}>
          {props.children}
        </UserContext.Provider>
      )
}