import * as Mui from "@mui/material";

export default function DisplayCard(props) {
    return(
        <>
<Mui.Stack direction='column' spacing={1} justifyContent="center" alignItems="center">
            <Mui.Typography variant="h5" sx={{fontWeight: 400, fontSize: 30 ,fontFamily: 'STZhongsong' }}>
            {props.string == null ? '-': props.string}
            </Mui.Typography>
            <Mui.Divider variant="middle" flexItem/>
            <Mui.Stack spacing= {1} direction="row" justifyContent="center" alignItems="center">
            {props.unit != null && 
            <Mui.Typography sx={{fontSize: 35}} mr={-1}>
              {props.unit}
            </Mui.Typography>}
  
                <Mui.Typography sx={{fontSize: 35}}>
                     {props.value == null ? '-': props.value}
                </Mui.Typography>
            {((props.change== null) ? <></>:
            props.change == '0' ? 
              <Mui.Chip label={'+0.00' + '%'} color="grey" size="middle" sx={{fontSize: 30}}/>:
               (props.change > 0 ? 
                <Mui.Chip label={'+' + (props.change * 100).toFixed(2) + '%'} color="red" size="middle" sx={{fontSize: 25}}/> :
                <Mui.Chip label={(props.change * 100).toFixed(2) + '%'} color="green" size="middle" sx={{fontSize: 25}}/>
                
              ))}
              {props.suffix != null && 
              <Mui.Typography sx={{fontSize: 25, fontFamily: 'STZhongsong'}} mr={-1}>
                {props.suffix}
              </Mui.Typography>}
            </Mui.Stack>  
        </Mui.Stack>
        </>
    )}