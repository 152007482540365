import * as Mui from "@mui/material";
import { ReactComponent as LogoSvg } from '../logo.svg'
import { ReactComponent as LogoIconSvg } from '../logo_2_icon.svg'
import { forwardRef } from 'react';

export const FontAwesomeSvgIcon = forwardRef((props, ref) => {
  const { icon } = props;

  const {
    icon: [width, height, , , svgPathData],
  } = icon;

  return (
    <Mui.SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`}>
      {typeof svgPathData === 'string' ? (
        <path d={svgPathData} />
      ) : (
        /**
         * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
         * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
         * of a duotone icon. 40% is the default opacity.
         *
         * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
         */
        svgPathData.map((d, i) => (
          <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />
        ))
      )}
    </Mui.SvgIcon>
  );
});

export const LogoIcon = (props) => <Mui.SvgIcon {...props} component={LogoIconSvg} viewBox="0 0 23 23" />
export const HomeIcon = (props) => (
  <Mui.SvgIcon {...props} viewBox="0 0 24 24">
    <path d="m 12,3.4042969 c -0.386472,0 -0.774364,0.1125969 -1.109375,0.3359375 l -7,4.6660156 C 3.3349585,8.7766943 3,9.4024845 3,10.070313 V 19 c 0,0.547842 0.2248563,1.052981 0.5859375,1.414062 C 3.9470187,20.775144 4.4521576,21 5,21 h 14 c 0.547842,0 1.052981,-0.224856 1.414062,-0.585938 C 20.775144,20.052981 21,19.547842 21,19 V 10.070313 C 21,9.4024846 20.665042,8.7766943 20.109375,8.40625 l -7,-4.6660156 C 12.774364,3.5168938 12.386472,3.4042969 12,3.4042969 Z m 0,1.5 c 0.09697,0 0.192481,0.027409 0.277344,0.083984 l 7,4.6660157 C 19.417173,9.7475166 19.5,9.9022583 19.5,10.070313 V 19 c 0,0.142513 -0.05515,0.262186 -0.146484,0.353516 C 19.262186,19.444845 19.142513,19.5 19,19.5 H 5 C 4.8574872,19.5 4.7378137,19.444845 4.6464844,19.353516 4.555155,19.262186 4.5,19.142513 4.5,19 V 10.070313 C 4.5,9.9022584 4.5828267,9.7475165 4.7226562,9.6542969 L 11.722656,4.9882812 C 11.807519,4.9317059 11.903034,4.9042969 12,4.9042969 Z" />
    <rect x="9" y="16" width="6" height="1.5" rx="0.75"></rect>
  </Mui.SvgIcon>
)
export const WalletIcon = (props) => (
  <Mui.SvgIcon {...props} viewBox="0 0 24 24">
    <path d="m 12,2 c -4.4093901,0 -8,3.5906099 -8,8 0,4.40939 3.5906099,8 8,8 4.40939,0 8,-3.59061 8,-8 0,-4.4093901 -3.59061,-8 -8,-8 z m 0,1.5 c 3.598731,0 6.5,2.9012693 6.5,6.5 0,3.598731 -2.901269,6.5 -6.5,6.5 C 8.4012693,16.5 5.5,13.598731 5.5,10 5.5,6.4012693 8.4012693,3.5 12,3.5 Z" />
    <path d="m 8,19 c -0.5453508,0 -1,0.454649 -1,1 0,0.545351 0.4546492,1 1,1 h 8 c 0.545351,0 1,-0.454649 1,-1 0,-0.545351 -0.454649,-1 -1,-1 z" />
  </Mui.SvgIcon>
)
export const TxnIcon = (props) => (
  <Mui.SvgIcon {...props} viewBox="0 0 24 24">
    <path d="m 12.128906,5.25 c -0.435758,0 -0.862004,0.042473 -1.27539,0.125 a 0.75,0.75 0 0 0 -0.589844,0.8828125 0.75,0.75 0 0 0 0.882812,0.5878906 C 11.463996,6.7823166 11.792099,6.75 12.128906,6.75 c 2.732862,0 4.966316,2.1876077 5.113281,4.974609 a 0.75,0.75 0 0 0 0.789063,0.708985 0.75,0.75 0 0 0 0.708984,-0.78711 C 18.55303,8.096409 15.664666,5.25 12.128906,5.25 Z m -5.9335935,5.009766 a 0.75,0.75 0 0 0 -0.859375,0.621093 C 5.2796714,11.229821 5.25,11.587634 5.25,11.951172 c 0,3.567277 2.8125742,6.495258 6.363281,6.777344 a 0.75,0.75 0 0 0 0.806641,-0.689454 0.75,0.75 0 0 0 -0.6875,-0.80664 C 8.931821,17.009928 6.75,14.731783 6.75,11.951172 c 0,-0.28337 0.022797,-0.561569 0.066406,-0.832031 A 0.75,0.75 0 0 0 6.1953125,10.259766 Z" />
    <path d="M 5,3.25 C 4.0434739,3.25 3.25,4.0434739 3.25,5 v 4 c 0,0.9565261 0.7934739,1.75 1.75,1.75 h 4 c 0.9565261,0 1.75,-0.7934739 1.75,-1.75 V 5 C 10.75,4.0434739 9.9565261,3.25 9,3.25 Z m 0,1.5 h 4 c 0.1514728,0 0.25,0.098527 0.25,0.25 V 9 C 9.25,9.1514728 9.1514728,9.25 9,9.25 H 5 C 4.8485272,9.25 4.75,9.1514728 4.75,9 V 5 C 4.75,4.8485272 4.8485272,4.75 5,4.75 Z" />
    <path d="m 13,11.25 c -0.956526,0 -1.75,0.793474 -1.75,1.75 v 6 c 0,0.956526 0.793474,1.75 1.75,1.75 h 6 c 0.956526,0 1.75,-0.793474 1.75,-1.75 v -6 c 0,-0.956526 -0.793474,-1.75 -1.75,-1.75 z m 0,1.5 h 6 c 0.151473,0 0.25,0.09853 0.25,0.25 v 6 c 0,0.151473 -0.09853,0.25 -0.25,0.25 h -6 c -0.151473,0 -0.25,-0.09853 -0.25,-0.25 v -6 c 0,-0.151473 0.09853,-0.25 0.25,-0.25 z" />
  </Mui.SvgIcon>
)
export const NFTIcon = (props) => (
  <Mui.SvgIcon {...props} viewBox="0 0 24 24">
    <path d="m 6,11.25 c -1.4592319,0 -2.75,1.070269 -2.75,2.5 v 3.5 c 0,1.429731 1.2907681,2.5 2.75,2.5 h 5 v -1.5 H 6 c -0.7499049,0 -1.25,-0.496737 -1.25,-1 v -3.5 c 0,-0.503263 0.5000951,-1 1.25,-1 h 4 v -1.5 z" />
    <path d="m 13,4.25 v 1.5 h 5 c 0.749905,0 1.25,0.4967366 1.25,1 v 3.5 c 0,0.503263 -0.500095,1 -1.25,1 h -4 v 1.5 h 4 c 1.459232,0 2.75,-1.070269 2.75,-2.5 v -3.5 c 0,-1.4297313 -1.290768,-2.5 -2.75,-2.5 z" />
    <path d="m 12,3 c -1.095685,0 -2,0.9043151 -2,2 0,1.0956849 0.904315,2 2,2 1.095685,0 2,-0.9043151 2,-2 0,-1.0956849 -0.904315,-2 -2,-2 z m 0,1.5 c 0.285026,0 0.5,0.2149744 0.5,0.5 0,0.2850256 -0.214974,0.5 -0.5,0.5 -0.285026,0 -0.5,-0.2149744 -0.5,-0.5 0,-0.2850256 0.214974,-0.5 0.5,-0.5 z" />
    <path d="m 12,9 c -1.647969,0 -3,1.352031 -3,3 0,1.647969 1.352031,3 3,3 1.647969,0 3,-1.352031 3,-3 0,-1.647969 -1.352031,-3 -3,-3 z m 0,1.5 c 0.83731,0 1.5,0.66269 1.5,1.5 0,0.83731 -0.66269,1.5 -1.5,1.5 -0.83731,0 -1.5,-0.66269 -1.5,-1.5 0,-0.83731 0.66269,-1.5 1.5,-1.5 z" />
    <path d="m 12,17 c -1.095685,0 -2,0.904315 -2,2 0,1.095685 0.904315,2 2,2 1.095685,0 2,-0.904315 2,-2 0,-1.095685 -0.904315,-2 -2,-2 z m 0,1.5 c 0.285026,0 0.5,0.214974 0.5,0.5 0,0.285026 -0.214974,0.5 -0.5,0.5 -0.285026,0 -0.5,-0.214974 -0.5,-0.5 0,-0.285026 0.214974,-0.5 0.5,-0.5 z" />
  </Mui.SvgIcon>
)
export const DaoIcon = (props) => (
  <Mui.SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M3.88604158,13.5000512 C4.50209323,16.8537725 7.14622746,19.4979068 10.4999488,20.1139584 L10.4999178,21.635315 C6.31630382,20.9892811 3.01071895,17.6836962 2.36468497,13.5000822 Z M21.635315,13.5000822 C20.9892811,17.6836962 17.6836962,20.9892811 13.5000822,21.635315 L13.5000512,20.1139584 C16.8537725,19.4979068 19.4979068,16.8537725 20.1139584,13.5000512 Z M13.5000822,2.36468497 C17.6836962,3.01071895 20.9892811,6.31630382 21.635315,10.4999178 L20.1139584,10.4999488 C19.4979068,7.14622746 16.8537725,4.50209323 13.5000512,3.88604158 Z M10.4999178,2.36468497 L10.4999488,3.88604158 C7.14622746,4.50209323 4.50209323,7.14622746 3.88604158,10.4999488 L2.36468497,10.4999178 C3.01071895,6.31630382 6.31630382,3.01071895 10.4999178,2.36468497 Z"></path>
    <path d="m 12,8 c -2.2002533,0 -4,1.7997467 -4,4 0,2.200253 1.7997467,4 4,4 2.200253,0 4,-1.799747 4,-4 0,-2.2002533 -1.799747,-4 -4,-4 z m 0,1.5 c 1.389594,0 2.5,1.110406 2.5,2.5 0,1.389594 -1.110406,2.5 -2.5,2.5 -1.389594,0 -2.5,-1.110406 -2.5,-2.5 0,-1.389594 1.110406,-2.5 2.5,-2.5 z" />
  </Mui.SvgIcon>
)