import React from 'react';
import * as Mui from "@mui/material";
import Panel from '../utils/Panel';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useHistory  } from "react-router-dom";
import { ServerURL } from '../utils/Config'
import { useIntl } from 'react-intl';
import Banner from './Components/Banner';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios'
import { useUser } from '../providers/User';
import { useMessage } from '../providers/Message';

export default function Login() {
    const intl = useIntl()
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const elementRef = useRef();
    const location = useLocation();
    const history = useHistory();
    const {updateToken, profile, token} = useUser();
    const {message} = useMessage();


    useEffect(() => {
        if (profile){
            var prev_path = (new URL(window.location.href.replace('#',))).searchParams.get('prev_path');
            if (prev_path) {
                history.push(prev_path.indexOf('#')?prev_path.split('#').pop():prev_path);
            } else {
                history.push('/mine');
            }
        }
      }, [profile])


    const handleClick = () => {
        if (username.length == 0 || password.length == 0) {
            message('用户名密码不能为空!');
            return false;
        }
        var prev_path = (new URL(window.location.href.replace('#',))).searchParams.get('prev_path')
        var url = prev_path ? `/user/login?prev_path=${prev_path.replaceAll('/','%2F').replaceAll('#','%23')}&api=true` : '/user/login?api=true'
        axios.post(ServerURL + url, JSON.stringify({
            username: username,
            password: password
          }),{
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(function (response) {
            if (response.data.success) {
                message('登录成功');
                updateToken(response.data.data);
            }
            else {
                message('登录失败，请检查用户名密码');
                setPassword('');
            }
          })
      };
    

    
    return (
        <Mui.Grid container direction="column" spacing={3} ref={elementRef}>
            <Banner/>
            <Mui.Grid item>
                <Panel title={'用户登陆'}>
                
                <Mui.Grid container justifyContent='center'>
                        <Mui.Grid item  sm={3}/>
                        <Mui.Grid container item direction='column' spacing={3}  width="90%" xs={12} sm={9} pl={1}>

                            <Mui.Grid item container alignItems="center" pt={3}>
                            <Mui.Grid item xs={3} >
                                <Mui.Typography variant="body1" component="div">
                                用户名：
                                </Mui.Typography>
                            </Mui.Grid>
                            <Mui.Grid item xs={8} md={6} lg={4} >
                                <Mui.TextField
                                type="text"
                                size="small"
                                margin="normal"
                                value={username}
                                onChange={(event) => { setUsername(event.target.value);}} 
                                fullWidth
                                sx={{mt: 1}}
                                FormHelperTextProps={{
                                    sx: {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                    }
                                }}
                                />
                            </Mui.Grid>
                            </Mui.Grid>

                            <Mui.Grid item container alignItems="center" >
                                <Mui.Grid item xs={3} >
                                    <Mui.Typography variant="body1" component="div">
                                    密码：
                                    </Mui.Typography>
                                </Mui.Grid>
                                <Mui.Grid item xs={8} md={6} lg={4}>
                                    <Mui.TextField
                                    type="password"
                                    size="small"
                                    margin="normal"
                                    value={password}
                                    onChange={(event) => { setPassword(event.target.value);}} 
                                    fullWidth
                                    sx={{mt: 1}}
                                    FormHelperTextProps={{
                                        sx: {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                        }
                                    }}
                                    />
                                </Mui.Grid>
                            </Mui.Grid>

                            <Mui.Grid item container alignItems="center" pb={4} mt={3}>
                                <Mui.Grid item xs={6} md={3}>
                                    <Mui.Button onClick={handleClick} fullWidth variant="contained" >
                                    登陆
                                    </Mui.Button>
                                </Mui.Grid>
                                <Mui.Grid item xs={0} md={1}/>
                                <Mui.Grid item xs={6} md={3} >
                                
                                    <Mui.Button fullWidth variant="text" href="/#/more">
                                  
                                         联系我们
                  
                                    </Mui.Button>
                                
                                
                                </Mui.Grid>
                            </Mui.Grid>
                            
                        </Mui.Grid>
                        
                        </Mui.Grid>
                    


                </Panel>
            </Mui.Grid>
        </Mui.Grid>
    )
}