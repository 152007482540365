import * as Mui from "@material-ui/core";
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useIntl } from 'react-intl';
import Banner from './Components/Banner';
import InfoLine from './Components/InfoLine';
import axios from 'axios'
import { ServerURL } from '../utils/Config'
import Panel from '../utils/Panel'
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { chartsGridClasses } from '@mui/x-charts/ChartsGrid';



function Charts (){
  const [range, setRange] = useState('day')

  const handleChange = (
    event, new_range
  ) => {
    setRange(new_range);
  };

  const range_and_dots = {
    'day' : [30, 30],
    'week': [365,30]
  }

  const [data, setData] = useState([[],[]])
  useEffect(() => {
    axios(ServerURL+`/fund/history?time_range=${range_and_dots[range][0]}&dots=${range_and_dots[range][1]}`).then((response) => {
      if (response.data.success) {
        var new_data = [];
        var times = [];
        var datas = [];
        response.data.data.forEach((item) => {
          times.push(new Date(item.date).toLocaleDateString('zh-cn', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            }))
          //times.push(item.date)
          datas.push(item.value_per_share/10000)
        });
        new_data.push(times);
        new_data.push(datas);
        setData(new_data)
        console.log(times)
      }
    })
  }, [])



  if(data[0].length == 0) {
    return (<Mui.Skeleton variant="rectangular" width={60} />)
  }

 return (
  <Mui.Stack direction="column" spacing={2} alignItems="center" sx={{ width: '100%' }}>
    {/* <Mui.ToggleButtonGroup
        // orientation="vertical"
        value={range}
        exclusive
        onChange={handleChange}
      >
          <Mui.ToggleButton value="day">日线</Mui.ToggleButton>
          <Mui.ToggleButton value="week">周线</Mui.ToggleButton>
      </Mui.ToggleButtonGroup> */}
    <LineChart
      xAxis={[{ 
        id: 'time',
        data: data[0],
        scaleType: 'point',
        // valueFormatter:  (date) =>
        //     date.toLocaleDateString('zh-cn', {
        //       year: '2-digit',
        //       month: '2-digit',
        //       day: '2-digit',
        //     }),
        min: data[0][0],
        max: data[0][data[0].length-1],
       }]}
       
       yAxis={[
        { 
          min: Math.min.apply(Math, data[1])*0.89,
          max: Math.max.apply(Math, data[1])*1.11,
          id: 'value'
        },
      ]}
      leftAxis={{
        axisId : 'value',
        disableLine : true,
        disableTicks: true,
        tickFontSize: 18,
        tickLabelStyle: {
            transform: `translateX(-5px)`
        },
      }}
      bottomAxis={{
        axisId : 'time',
        tickFontSize: 18,
        tickSize: 0,
        tickLabelStyle: {
            transform: `translateY(15px)`
        },
      }}
       grid={{ horizontal: true }}
      sx={{
        [`& .${axisClasses.left} .${axisClasses.label}`]: {
          transform: 'translateX(-10px)',
        },
        [`& .${chartsGridClasses.line}`]: { strokeDasharray: '5 3', strokeWidth: 3 },
        '& .MuiLineElement-root': {
          strokeWidth: 4,
        },
        '& .MuiAreaElement-series-vps': {
          fill: "url('#gradient')",
        },
        "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
          stroke:"#696969",
          strokeWidth:4
          },
      }}
      series={[
        {
          id: 'vps',
          data: data[1],
          label: '份额净值(元)',
          color: '#F8674D',
          area: true,
          showMark: false,
        },
      ]}
      width={window.innerWidth > 1000 ? 700 : (
        window.innerWidth > 750 ? 500 : 350
      )}
      height={window.innerWidth > 1000 ? 450 : (
        window.innerWidth > 750 ? 300 : 300
      )}
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'top', horizontal: 'left' },
          padding: {left: 30, bottom: 10},
        },
      }}>
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(0)">
            <stop offset="5%" stopColor="#ffffff55" />
            <stop offset="25%" stopColor="#fcd1c955" />
            <stop offset="95%" stopColor="#fa917e55" />
          </linearGradient>
        </defs>
      </LineChart>
  </Mui.Stack>
);
}

export default function Home() {
  const intl = useIntl()
  const history = useHistory()
  const elementRef = useRef()
  const [status, setStatus] = useState({})

  useEffect(() => {
    axios(ServerURL+'/fund/profile').then((response) => {
      if (elementRef.current && response.data) {
        setStatus(response.data.data)
      }
    })
  }, [])

  return (
    <Mui.Grid container direction="column" spacing={3} ref={elementRef}>
      <Banner/>
      <Mui.Grid item container spacing={3}>
        <Mui.Grid item width="100%" md={6}>
          <Panel title={'基金详情'}>
            <Mui.Box px={4}>
            <InfoLine
                name={'已成功运行交易日'}
                value={status.last_day ? status.last_day.day : null}
                unit={'交易日'}
                precision={0}
              />
              <InfoLine
                name={'当前每份额折合净值'}
                value={status.last_day ? status.last_day.value_per_share : null}
                unit={'RMB'}
                precision={4}
              />
              <InfoLine
                name={'折合年化收益率'}
                string={status.last_day ? (((status.last_day.value_per_share/10000)**(365/status.last_day.day)-1)*100).toFixed(2)+' %' : 'pending'}
              />
            </Mui.Box>
          </Panel>
        </Mui.Grid>
        <Mui.Grid item width="100%" md={6}>
          <Panel title={'净值收益'}>
            <Mui.Box px={4}>
              <InfoLine
                name={'最近一个交易日净值收益'}
                change={status.change_day ? status.change_day.change_vps : 'pending'}
                unit={'RMB'}
                change_percent={status.change_day ? status.change_day.change_vps_percent : 'pending'}
                precision={4}
              />

              <InfoLine
                name={'最近七个交易日净值收益'}
                change={status.change_week ? status.change_week.change_vps : 'pending'}
                unit={'RMB'}
                change_percent={status.change_week ? status.change_week.change_vps_percent : 'pending'}
                precision={4}
              />
              <InfoLine
                name={'最近三十交易日净值收益'}
                change={status.change_month ? status.change_month.change_vps : 'pending'}
                unit={'RMB'}
                change_percent={status.change_month ? status.change_month.change_vps_percent : 'pending'}
                precision={4}
              />
            </Mui.Box>
          </Panel>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid item>
        <Panel title={'净值曲线'}>
          <Charts/>
        </Panel>
      </Mui.Grid>
    </Mui.Grid>
  )
}
