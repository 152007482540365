import * as Mui from "@mui/material";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


export default function InfoLine(props) {

    var precision = props.precision != null? props.precision : 2
  
    return (
      <>
        <Mui.Typography gutterBottom variant="subtitle2" component="div">
          {props.name}{props.tooltip &&
            <Mui.Tooltip placement="top" title={props.tooltip}>
              <Mui.IconButton size="small">
                <HelpOutlineIcon fontSize="small" />
              </Mui.IconButton>
            </Mui.Tooltip>
          }
        </Mui.Typography>
        <Mui.Grid container spacing={1} justifyContent="flex-start" alignItems="center" wrap="nowrap">
          
          {props.string == null || <Mui.Grid item zeroMinWidth ml={3}>

          {props.string == 'pending' ? 
              <Mui.Skeleton variant="rectangular" width={60} />:
              <Mui.Typography  gutterBottom noWrap variant="h6" component="div" sx={{ fontWeight: 400 }}> 
                  { props.string }
                </Mui.Typography>}

          
          </Mui.Grid>}

          {props.value == null || <Mui.Grid item zeroMinWidth ml={3}>
  
            {props.value == 'pending' ? 
              <Mui.Skeleton variant="rectangular" width={60} />:
              <Mui.Typography  gutterBottom noWrap variant="h6" component="div" sx={{ fontWeight: 400 }}> 
                  { (props.value/10**precision).toLocaleString('zh-cn', {maximumFractionDigits: precision, minimumFractionDigits: precision}) }
                </Mui.Typography>}
    
          </Mui.Grid>}
  
  
          {props.change == null || <Mui.Grid item zeroMinWidth ml={3}>
  
              {Number(props.change) > 0 ? 
                <Mui.Typography  gutterBottom noWrap variant="h6" component="div" sx={{ fontWeight: 400 }}> 
                  {'+ ' + (Number(props.change)  / 10**precision).toFixed(precision) }
                </Mui.Typography>:
                <Mui.Typography  gutterBottom noWrap variant="h6" component="div" sx={{ fontWeight: 400 }}> 
                  { (Number(props.change)  / 10**precision).toFixed(precision) }
                </Mui.Typography>
                }
    
          </Mui.Grid>}
  
          {props.unit == null || <Mui.Grid item >
            <Mui.Typography mb={-1} variant="overline" component="div">
              {typeof props.unit === 'undefined' ? <Mui.Skeleton variant="rectangular" width={30} /> : props.unit}
            </Mui.Typography>
          </Mui.Grid>}
  
          {props.change_percent == null || <Mui.Grid item  ml={2} mb={1}>
              {props.change_percent == 'pending' ? 
              <Mui.Chip label={'+' + 'NaN' + '%'} color="red" size="small"/>:
              (props.change_percent == 0 ? 
                <Mui.Chip label={(props.change_percent * 100).toFixed(2) + '%'} color="grey" size="small"/>:
              (props.change_percent > 0 ? 
                <Mui.Chip label={'+' + (props.change_percent * 100).toFixed(2) + '%'} color="red" size="small"/> :
                <Mui.Chip label={(props.change_percent * 100).toFixed(2) + '%'} color="green" size="small"/>
                
              ))}
  
          </Mui.Grid>}
        </Mui.Grid>
      </>
    )
  }