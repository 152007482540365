import * as Mui from "@mui/material";

export default function Panel(props) {
  return (
    <Mui.Paper sx={{
      py: 3,
      height: '100%'
    }}>
      <Mui.Box px={2}>
        <Mui.Grid container mb={3} alignItems="center" justifyContent="flex-end" spacing={2}>
          {props.title && <Mui.Grid item mr="auto">
            <Mui.Box sx={{
              ml: -2,
              pr: 2,
              border: (theme) => `1rem solid ${theme.palette.primary.main}`,
              borderRight: '1rem solid transparent',
              height: '0px',
              color: (theme) => theme.palette.primary.contrastText
            }}>
              <Mui.Typography variant="subtitle1" component="h3" noWrap sx={{
                mt: -2,
                lineHeight: 2
              }}>
                {props.title}
              </Mui.Typography>
            </Mui.Box>
          </Mui.Grid>}
          {props.badges && props.badges.map((badge) => <Mui.Grid item>{badge}</Mui.Grid>)}
        </Mui.Grid>
      </Mui.Box>
      {props.children}
    </Mui.Paper>
  )
}