import { useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import {
  HashRouter as Router
} from "react-router-dom";

import { useQuery } from '../utils/Router'
import ThemeProvider from './Theme'
import { UserProvider } from './User';
import { MessageProvider } from './Message';


function MultiProviders(props) {
  const lang = useQuery('lang')
  const locale = useMemo(() => lang || navigator.language, [lang])

  return (
    <MessageProvider>
      <UserProvider>
        <ThemeProvider>
          <IntlProvider locale={locale} defaultLocale={locale}>
            {props.children} 
          </IntlProvider>
        </ThemeProvider>
      </UserProvider>
    </MessageProvider>
  )
}

export default function Providers(props) {
  return (
    <Router>
      <MultiProviders>
        {props.children}
      </MultiProviders>
    </Router>
  )
}
