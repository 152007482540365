import React from 'react';
import ReactDOM from 'react-dom';
import Body from './pages';
import Providers from './providers'
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// 严格模式存在双重渲染问题，见 https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode
const strict = false
const app = (
  <Providers>
    <Body />
  </Providers>
)

ReactDOM.render(strict ?
  <React.StrictMode>
    {app}
  </React.StrictMode> : app,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
