import * as Mui from "@mui/material";
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useIntl } from 'react-intl';
import Banner from './Components/Banner';
import InfoLine from './Components/InfoLine';
import axios from 'axios'
import { LogoIcon } from '../utils/Icons'
import { ServerURL } from '../utils/Config'
import Panel from '../utils/Panel'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { chartsGridClasses } from '@mui/x-charts/ChartsGrid';
import { useUser } from '../providers/User';
import { useMessage } from '../providers/Message';


function Charts (){
  const [range, setRange] = useState('day')
  const {updateToken, profile, token} = useUser(); 

  const handleChange = (
    event, new_range
  ) => {
    setRange(new_range);
  };

  const range_and_dots = {
    'day' : [30, 30],
    'week': [365,30]
  }

  const getChartData = () =>{
    axios.get(ServerURL+`/user/history?time_range=${range_and_dots[range][0]}&dots=${range_and_dots[range][1]}&api=True`,
      {
        headers: {
          'Authorization': token
        }
      }
    ).then((response) => {
      if (response.data.success) {
        var new_data = [];
        var times = [];
        var datas = [];
        response.data.data.forEach((item) => {
          times.push(new Date(item.date))
          datas.push(item.amount/100)
        });
        new_data.push(times);
        new_data.push(datas);
        setData(new_data)
      }
    })
  }

  const [data, setData] = useState([[],[]])
  useEffect(() => {
    getChartData()
  }, [token])

  useEffect(() => {
    getChartData()
  }, [])


  if(data[0].length == 0) {
    return (<Mui.Skeleton variant="rectangular" width={60} />)
  }

 return (
  <Mui.Stack direction="column" spacing={2} alignItems="center" sx={{ width: '100%' }}>
    {/* <Mui.ToggleButtonGroup
        // orientation="vertical"
        value={range}
        exclusive
        onChange={handleChange}
      >
          <Mui.ToggleButton value="day">日线</Mui.ToggleButton>
          <Mui.ToggleButton value="week">周线</Mui.ToggleButton>
      </Mui.ToggleButtonGroup> */}
    <LineChart
      xAxis={[{ 
        id:'time',
        data: data[0],
        scaleType: 'time',
        valueFormatter:  (date) =>
            date.toLocaleDateString('zh-cn', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            }),
            min: data[0][0],
            max: data[0][data[0].length-1]
        }]}
        yAxis={[
          { 
            id:'value',
            min: Math.min.apply(Math, data[1])*0.89,
            max: Math.max.apply(Math, data[1])*1.11
          },
        ]}
        leftAxis={{
          axisId : 'value',
          disableLine : true,
          disableTicks: true,
          tickFontSize: 15,
        }}
        bottomAxis={{
          axisId : 'time',
          tickFontSize: 15,
        }}
       grid={{ horizontal: true }}
       sx={{
        [`& .${axisClasses.left} .${axisClasses.label}`]: {
          transform: 'translateX(-10px)',
        },
        [`& .${chartsGridClasses.line}`]: { strokeDasharray: '5 3', strokeWidth: 2.5 },
        '& .MuiLineElement-root': {
          strokeWidth: 4,
        },
        '& .MuiAreaElement-series-mine': {
          fill: "url('#gradient')",
        },
      }}
      series={[
        {
          data: data[1],
          id:'mine',
          label: '我的持仓(元)',
          color: '#F8674D',
          area: true,
          showMark: false,
        },
      ]}
      width={window.innerWidth > 1000 ? 700 : (
        window.innerWidth > 750 ? 500 : 350
      )}
      height={window.innerWidth > 1000 ? 450 : (
        window.innerWidth > 750 ? 300 : 300
      )}
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'top', horizontal: 'left' },
          padding: {left: 30, bottom: 10},
        },
      }}
      margin={{ left: 70 }}
      >
      <defs>
      <linearGradient id="gradient" gradientTransform="rotate(0)">
        <stop offset="5%" stopColor="#ffffff55" />
        <stop offset="25%" stopColor="#fcd1c955" />
        <stop offset="95%" stopColor="#fa917e55" />
      </linearGradient>
    </defs>
  </LineChart>
  </Mui.Stack>
);
}

export default function Mine() {
  const intl = useIntl()
  const history = useHistory()
  const elementRef = useRef()
  const [status, setStatus] = useState({})
  const {updateToken, profile, token} = useUser();


  const {message} = useMessage();
  
  const log_out = () =>{
    axios.post(ServerURL+'/user/logout',{},
        {
          headers: {
            'Authorization': token
          }
        }
      )
      .then(function (response) {
        if (response.data.success) {
          updateToken('');
          message('退出成功');
          history.push('/');
        }
      });
  }

  return (
    <Mui.Grid container direction="column" spacing={3} ref={elementRef}>
      <Banner/>
      <Mui.Grid item container spacing={3}>
        <Mui.Grid item width="100%" md={6}>
          <Panel title={'用户详情'}>
            <Mui.Stack direction="column"  px={4}>
                <Mui.Stack direction="row" mt={2} mb={3} spacing={2} alignItems='center' >
                  <Mui.Typography variant="body1" component="div" ml={3} mr={3}>
                      欢迎您，  {profile?.nickname}
                  </Mui.Typography>
                  <Mui.Button variant="contained" size="small" href="/#/changepwd" style={{minWidth: '70px'}}>     
                        修改密码
                  </Mui.Button>
                  <Mui.Button variant="outlined" size="small" onClick={log_out} style={{minWidth: '70px'}}>     
                        退出登陆
                  </Mui.Button>
                </Mui.Stack>
                <InfoLine
                name={'我的份额'}
                value={profile?.share_record ? profile?.share_record.shares : 'pending'}
              />
              <InfoLine
                name={'当前净值'}
                value={profile?.share_record ? profile?.share_record.fund_list[0].value_per_share : 'pending'}
                unit={'RMB'}
                precision={4}
                change_percent={profile?.last_day ? profile?.last_day.change_vps/10000 : 'pending'}
              />
            </Mui.Stack>
           
          </Panel>
        </Mui.Grid>
        <Mui.Grid item width="100%" md={6}>
          <Panel title={'我的持仓'}>
            <Mui.Box px={4}>
              <InfoLine
                name={'我的持仓'}
                value={profile?.share_record ? profile.share_record.amount : 'pending'}
                unit={'RMB'}
              />
              <InfoLine
                name={'今日收益'}
                change={profile?.change_day ? profile.change_day.change_amt : 'pending'}
                unit={'RMB'}
                change_percent={profile?.change_day ? (profile.change_day.change_amt/( profile.share_record.amount - profile.change_day.change_amt)) : 'pending'}
              />
              <InfoLine
                name={'总收益'}
                change={profile?.total_profit ? profile.total_profit : 'pending'}
                unit={'RMB'}
                change_percent={profile?.total_profit ? (profile.total_profit/(profile.share_record.amount-profile.total_profit)) : 'pending'}
              />
            </Mui.Box>
          </Panel>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid item>
        <Panel title={'收益曲线'}>
          <Charts/>
        </Panel>
      </Mui.Grid>
      
    </Mui.Grid>
  )
}
