import Home from './Home'
import Mine from './Mine'
import Login from './Login'
import Guide from './Guide'
import Tickets from './Tickets'
import ChangePwd from './ChangePwd'
import { faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { NavLink } from '../utils/Router'
import { Switch, Route, Redirect } from "react-router-dom";
import { useIntl } from 'react-intl'
import * as Mui from '@mui/material';
import { LogoIcon, HomeIcon, WalletIcon, TxnIcon, DaoIcon, NFTIcon, FontAwesomeSvgIcon } from '../utils/Icons'
import { bottomNavigationHeight } from '../providers/Theme'
import { useUser } from '../providers/User';
import HQFund from './HQ/HQFund'
import HQIndex from './HQ/HQIndex'




export default function Body() {
  const intl = useIntl()
  const {updateToken, profile, token} = useUser();

  function RequirAuthRoute({children}) {
    //test
    //return children
    if(profile == null){
      var index = window.location.href.split('//')[1].indexOf('/');
      if (index > 0){
        const prev_url = window.location.href.split('//')[1].substring(index).split('?')[0]
        return <Redirect to={"/login?prev_path=" +prev_url.replaceAll('/','%2F').replace('#','%23')} />
      }
      else{
        return <Redirect to="/login" />
      }
    }
    return children
   
  }

  return (
    <>
  <Switch>
    <Route path="/hq/fund">
      <HQFund/>
    </Route>
    <Route path="/hq/index">
      <HQIndex/>
    </Route>
    <Route>
      <Mui.Container maxWidth="lg" disableGutters>
        <Mui.Grid container spacing={3} direction="column" sx={{ p: { xs: 2, sm: 3 }, pb: { xs: `${bottomNavigationHeight}px`, sm: 3 }, minHeight: 'calc(100vh + 24px)' }}>
          <Mui.Grid component="header" item>
            <Mui.Toolbar disableGutters>
              <LogoIcon sx={{ mr: 2, fontSize: { xs: '1.5rem', sm: '2.125rem' } }} />
              <Mui.Stack direction="row">
                <Mui.Typography
                  variant="h5"
                  component="h2"
                  sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' }, flexShrink: 0, fontWeight: 500 }}
                >
                  必乐
                </Mui.Typography>
                <Mui.Typography
                  variant="h5"
                  component="h2"
                  sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' }, flexShrink: 0, color: '#F8674D', fontWeight: 500 }}
                >
                  量子
                </Mui.Typography>
              </Mui.Stack>
              <Mui.Box sx={{ flexGrow: 1 }} />
            </Mui.Toolbar>
          </Mui.Grid>
          <Mui.Grid item container spacing={3} wrap="nowrap" flexGrow={1}>
            <Mui.Grid item display={{ xs: 'none', sm: 'block' }}>
              <Mui.Paper component="nav"
                sx={{
                  px: 0,
                  py: (theme) => `${theme.shape.borderRadius}px`,
                  width: { xs: 160, md: 200 },
                  '& .Mui-selected': {
                    borderLeft: (theme) => `5px solid ${theme.palette.primary.main}`,
                    paddingLeft: 'calc(1rem - 5px)'
                  },
                }}
              >
                <Mui.List>
                  <Mui.ListItemButton
                    button
                    component={NavLink}
                    to="/"
                    activeClassName="Mui-selected"
                    exact
                  >
                    <Mui.ListItemIcon>
                      <HomeIcon color="primary" />
                    </Mui.ListItemIcon>
                    <Mui.ListItemText primary={<Mui.Typography  style={{ color: 'black' }}>基金总览</Mui.Typography>} />
                  </Mui.ListItemButton>


                  <Mui.ListItemButton
                    button
                    component={NavLink}
                    to="/guide"
                    activeClassName="Mui-selected"
                  >
                    <Mui.ListItemIcon>
                      <NFTIcon color="primary" />
                    </Mui.ListItemIcon>
                    <Mui.ListItemText primary={<Mui.Typography  style={{ color: 'black' }}>详细介绍</Mui.Typography>}  />
                  </Mui.ListItemButton>

                  <Mui.ListItemButton
                    button
                    component={NavLink}
                    to="/mine"
                    activeClassName="Mui-selected"
                  >
                    <Mui.ListItemIcon>
                      <WalletIcon color="primary" />
                    </Mui.ListItemIcon>
                    <Mui.ListItemText primary={<Mui.Typography  style={{ color: 'black' }}>我的份额</Mui.Typography>}  />
                  </Mui.ListItemButton>


                  <Mui.ListItemButton
                    button
                    component={NavLink}
                    to="/more"
                    activeClassName="Mui-selected"
                  >
                    <Mui.ListItemIcon>
                      <DaoIcon color="primary" />
                    </Mui.ListItemIcon>
                    <Mui.ListItemText primary={<Mui.Typography  style={{ color: 'black' }}>联系我们</Mui.Typography>} />
                  </Mui.ListItemButton>


                </Mui.List>
              </Mui.Paper>
            </Mui.Grid>
            <Mui.Grid item component="main" flexGrow={1} width={0}>
              <Switch>
                <Route exact path="/">
                  <RequirAuthRoute>
                    <Home />
                  </RequirAuthRoute>
                </Route>
                <Route path="/mine">
                  <RequirAuthRoute>
                    <Mine />
                  </RequirAuthRoute>
                </Route>
                <Route path="/guide">
                  <Guide/>
                </Route>
                <Route path="/more">
                  <Tickets />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/changepwd">
                  <RequirAuthRoute>
                    <ChangePwd />
                  </RequirAuthRoute>
                </Route>
              </Switch>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid component="footer" item container spacing={1} justifyContent="center" alignItems="center">
            <Mui.Grid item container width="unset" spacing={2} alignItems="center" wrap="nowrap">
              <Mui.Grid item>
                <LogoIcon sx={{ fontSize: '2rem' }} />
              </Mui.Grid>
              <Mui.Grid item>
                <Mui.Stack direction="row">
                <Mui.Typography
                  variant="subtitle2"
                  component="div"
                >
                  必乐
                </Mui.Typography>
                <Mui.Typography
                  variant="subtitle2"
                  component="div"
                  sx={{color: '#F8674D' }}
                >
                  量子
                </Mui.Typography>
                <Mui.Typography
                  variant="subtitle2"
                  component="div"
                >
                  壹号
                </Mui.Typography>
              </Mui.Stack>
                <Mui.Typography variant="caption" component="div">
                  量子+赋能算法，专注先进量化策略研究
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid item flexGrow={1}></Mui.Grid>
            <Mui.Grid item container width="unset" spacing={1} justifyContent="center" alignItems="center">
              {/* <Mui.Grid item>
                <Mui.IconButton sx={{ mr: 1 }} size="small" href="https://github.com/webQfoundation" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeSvgIcon icon={faGithub} />
                </Mui.IconButton>
                <Mui.IconButton sx={{ mr: 1 }} size="small" href="https://twitter.com/WebQDAO" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeSvgIcon icon={faTwitter} />
                </Mui.IconButton>
              </Mui.Grid> */}
              <Mui.Grid item>
                {/* <Mui.Button size="small" href="https://docs.web-q.foundation" target="_blank" rel="noopener noreferrer">
                  {intl.formatMessage({ defaultMessage: 'Doc' })}
                </Mui.Button>
                <Mui.Button size="small" href="https://github.com/webQfoundation/webQ-contract" target="_blank" rel="noopener noreferrer">
                  {intl.formatMessage({ defaultMessage: 'Code' })}
                </Mui.Button>
                <Mui.Button size="small" href="https://medium.com/@webqdao/web-q-nft-issuance-plan-1a4d549af929" target="_blank" rel="noopener noreferrer">
                  {intl.formatMessage({ defaultMessage: 'About' })}
                </Mui.Button> */}
                <Mui.Typography variant="body" component="div">
                  闽ICP备18013953号-1
                </Mui.Typography>
              </Mui.Grid>
              
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Container>
      <Mui.Paper
        elevation={1}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          m: 2,
          p: 0,
          display: { xs: 'block', sm: 'none' },
          zIndex: (theme) => theme.zIndex.appBar
        }}>
        <Mui.BottomNavigation>
          <Mui.BottomNavigationAction
            label={intl.formatMessage({ defaultMessage: '主页' })}
            component={NavLink}
            to="/"
            activeClassName="Mui-selected"
            exact
            icon={<HomeIcon />}
          />
          <Mui.BottomNavigationAction
            label={intl.formatMessage({ defaultMessage: '详情' })}
            component={NavLink}
            to="/guide"
            activeClassName="Mui-selected"
            icon={<NFTIcon />}
          />
          <Mui.BottomNavigationAction
            label={intl.formatMessage({ defaultMessage: '我的' })}
            component={NavLink}
            to="/mine"
            activeClassName="Mui-selected"
            icon={<WalletIcon />}
          />
          <Mui.BottomNavigationAction
            label={intl.formatMessage({ defaultMessage: '更多' })}
            component={NavLink}
            to="/more"
            activeClassName="Mui-selected"
            icon={<DaoIcon />}
          />
        </Mui.BottomNavigation>
      </Mui.Paper>
    </Route>
  </Switch>
    </>

  );
}
