import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as Mui from "@mui/material";
import Panel from '../utils/Panel';
import Banner from './Components/Banner';
import { useUser } from '../providers/User';
import { useMessage } from '../providers/Message';
import { ServerURL } from '../utils/Config'
import axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



export default function Tickets() {
  const [contact, setContact] = useState("");
  const [content, setContent] = useState("");
  const [handled, setHandled] = useState(false);
  const {updateToken, profile, token} = useUser();
  const {message} = useMessage();

  useEffect(() => {
    if(profile){
      setContact(`#${profile.uid} ${profile.nickname}`);
    } else{
      setContact("");
    }
  }, [])

  useEffect(() => {
    if(profile){
      setContact(`#${profile.uid} ${profile.nickname}`);
    } else{
      setContact("");
    }
  }, [profile])

  const handleClick = () => {
    if (contact.length == 0 ) {
        message('联系方式不能为空!');
        return false;
    }
    if (content.length == 0 ) {
          message('工单内容不能为空！');
        return false;
    }
    axios.post(ServerURL + '/tickets/create', JSON.stringify({
        title: content,
        msg: contact
      }),{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      }).then(function (response) {
        if (response.data.success) {
            message('已成功提交工单');
            setHandled(true);
        }
        else {
            message('工单提交失败');
            setContent('');
        }
      })
  };
  return (
<Mui.Grid container direction="column" spacing={3} >
  <Banner/>
  <Mui.Grid item>
  <Panel title={'联系我们'}>
      { handled?
            <Mui.Grid container direction="column" justify="center" alignItems="center" spacing={3}>
              <Mui.Grid item mt={5} pb={3}>
                <CheckCircleIcon fontSize='large'/>
              </Mui.Grid>
              <Mui.Grid item>
                <Mui.Typography variant="h5" pb={5}>
                  反馈成功，请耐心等待工作人员处理！
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            :

          <Mui.Grid container justifyContent='center'>
              <Mui.Grid item xs={0} sm={1}/>
              <Mui.Grid container direction='column' spacing={3}  width="90%" xs={12} sm={11}>

                  <Mui.Grid item container alignItems="center" pt={3}>
                  <Mui.Grid item xs={5} md={3} >
                      <Mui.Typography variant="body1" component="div" mt={-6}>
                      工单内容：
                      </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={7} md={6} >
                      <Mui.TextField
                      type="text"
                      size="small"
                      margin="normal"
                      multiline
                      rows={4}
                      value={content}
                      onChange={(event) => { setContent(event.target.value);}} 
                      fullWidth
                      sx={{mt: 1}}
                      FormHelperTextProps={{
                          sx: {
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                          }
                      }}
                      />
                  </Mui.Grid>
                  </Mui.Grid>

                  <Mui.Grid item container alignItems="center" >
                      <Mui.Grid item xs={5} md={3}>
                          <Mui.Typography variant="body1" component="div">
                          联系方式：
                          </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid item xs={7} md={6} >
                          <Mui.TextField
                          type="text"
                          size="small"
                          margin="normal"
                          value={contact}
                          disabled={profile!=null}
                          onChange={(event) => { setContact(event.target.value);}} 
                          fullWidth
                          sx={{mt: 1}}
                          FormHelperTextProps={{
                              sx: {
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                              }
                          }}
                          />
                      </Mui.Grid>
                  </Mui.Grid>

                  <Mui.Grid item container alignItems="center"  pb={4} mt={3}>
                        <Mui.Grid item xs={1} md={1} />
                      <Mui.Grid item xs={8} md={6} >
                          <Mui.Button onClick={handleClick} fullWidth width="80%" variant="contained" >
                          提交工单
                          </Mui.Button>
                      </Mui.Grid>
                      
                  </Mui.Grid>
                  
              </Mui.Grid>
              
              </Mui.Grid>
                    

      }
            </Panel>
      </Mui.Grid>
</Mui.Grid>
    
    
  );
}

