import * as Mui from "@mui/material";
import { LogoIcon } from '../../utils/Icons'
import fullLogo from'../../logo_2_full.png';

export default function Banner() {
    return (
    <Mui.Grid item>
        <Mui.Paper sx={{
            py: 3,
            height: '100%'
        }}>
            <Mui.Grid container spacing={3} px={4} pt={3} justifyContent="center" alignItems="center" wrap="nowrap">
                <Mui.Grid item mb={3}>
                    <img  src={fullLogo} width={window.innerWidth > 400 ? 300 : 250}/>
                </Mui.Grid>
                {window.innerWidth > 1000 && 
                <>
                <Mui.Divider orientation="vertical" variant="middle" flexItem  sx={{ ml: "50px" }} />
                <Mui.Grid item mb={3} ml={3}>
                    <Mui.Stack direction="row">
                        <Mui.Typography variant="h4" sx={{fontWeight: 400, color: '#F8674D' }}>
                            量子+
                        </Mui.Typography>
                        <Mui.Typography variant="h4" sx={{fontWeight: 400 }}>
                            赋能算法
                        </Mui.Typography>
                    </Mui.Stack>
                    
                    <Mui.Typography variant="h5"  mt={1}>
                    专注先进量化策略研究
                    </Mui.Typography>
                </Mui.Grid>
                </>}
                {/* <Mui.Grid item>
                    <LogoIcon sx={{ fontSize: '4rem' }} />
                </Mui.Grid>
                <Mui.Grid item>
                    <Mui.Typography variant="h3" component="h2">
                    必乐量子壹号
                    </Mui.Typography>
                    <Mui.Typography variant="subtitle1" component="div">
                    量子+赋能算法，专注先进量化策略研究
                    </Mui.Typography>
                </Mui.Grid> */}
            </Mui.Grid>
        </Mui.Paper>
    </Mui.Grid>
    )
}