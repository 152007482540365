import React from 'react';
import * as Mui from "@mui/material";
import Panel from '../utils/Panel';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useHistory  } from "react-router-dom";
import { ServerURL } from '../utils/Config'
import { useIntl } from 'react-intl';
import Banner from './Components/Banner';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios'
import { useUser } from '../providers/User';
import { useMessage } from '../providers/Message';
import { Logout } from '@material-ui/icons';

export default function ChangePwd() {
    const intl = useIntl()
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repPassword, setRepPassword] = useState("");
    const elementRef = useRef();
    const location = useLocation();
    const history = useHistory();
    const {updateToken, profile, token} = useUser();
    const {message} = useMessage();
    const handleClick = () => {
        if (oldPassword.length == 0 || newPassword.length == 0) {
            message('密码不能为空!');
            return false;
        }
        if (newPassword != repPassword) {
            message('两次输入的密码不一致');
          return false;
      }
        axios.post(ServerURL + '/user/changepwd?api=True', JSON.stringify({
            new_password: newPassword,
            old_password: oldPassword
          }),{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token
            }
          }).then(function (response) {
            if (response.data.success) {
                message('修改密码成功，请重新登录');
                axios.post(ServerURL+'/user/logout?api=True',{},
                  {
                    headers: {
                      'Authorization': token
                    }
                  }
                )
                .then(function (response) {
                  if (response.data.success) {
                    updateToken('');
                    history.push('/mine');
                  }
                });
            }
            else {
                message('修改密码失败，请检查密码');
                setOldPassword('');
            }
          })
      };
    
    
    return (
        <Mui.Grid container direction="column" spacing={3} ref={elementRef}>
            <Banner/>
            <Mui.Grid item>
                <Panel title={'修改密码'}>
                
                <Mui.Grid container justifyContent='center'>
                        <Mui.Grid item xs={0} sm={1}/>
                        <Mui.Grid container direction='column' spacing={3}  width="90%" xs={12} sm={11}>

                            <Mui.Grid item container alignItems="center" pt={3}>
                            <Mui.Grid item xs={3} >
                                <Mui.Typography variant="body1" component="div">
                                旧密码：
                                </Mui.Typography>
                            </Mui.Grid>
                            <Mui.Grid item xs={8} md={6} lg={4} >
                                <Mui.TextField
                                type="password"
                                size="small"
                                margin="normal"
                                value={oldPassword}
                                onChange={(event) => { setOldPassword(event.target.value);}} 
                                fullWidth
                                sx={{mt: 1}}
                                FormHelperTextProps={{
                                    sx: {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                    }
                                }}
                                />
                            </Mui.Grid>
                            </Mui.Grid>

                            <Mui.Grid item container alignItems="center" >
                                <Mui.Grid item xs={3} >
                                    <Mui.Typography variant="body1" component="div">
                                    新密码：
                                    </Mui.Typography>
                                </Mui.Grid>
                                <Mui.Grid item xs={8} md={6} lg={4}>
                                    <Mui.TextField
                                    type="password"
                                    size="small"
                                    margin="normal"
                                    value={newPassword}
                                    onChange={(event) => { setNewPassword(event.target.value);}} 
                                    fullWidth
                                    sx={{mt: 1}}
                                    FormHelperTextProps={{
                                        sx: {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                        }
                                    }}
                                    />
                                </Mui.Grid>
                            </Mui.Grid>

                            <Mui.Grid item container alignItems="center" >
                                <Mui.Grid item xs={3} >
                                    <Mui.Typography variant="body1" component="div">
                                    重复新密码：
                                    </Mui.Typography>
                                </Mui.Grid>
                                <Mui.Grid item xs={8} md={6} lg={4}>
                                    <Mui.TextField
                                    type="password"
                                    size="small"
                                    margin="normal"
                                    value={repPassword}
                                    onChange={(event) => { setRepPassword(event.target.value);}} 
                                    fullWidth
                                    sx={{mt: 1}}
                                    FormHelperTextProps={{
                                        sx: {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                        }
                                    }}
                                    />
                                </Mui.Grid>
                            </Mui.Grid>

                            <Mui.Grid item container alignItems="center" pb={4} mt={3}>
                                <Mui.Grid item xs={6} md={6}>
                                    <Mui.Button onClick={handleClick} fullWidth variant="contained" >
                                    修改密码
                                    </Mui.Button>
                                </Mui.Grid>
                                
                            </Mui.Grid>
                            
                        </Mui.Grid>
                        
                        </Mui.Grid>
                       


                </Panel>
            </Mui.Grid>
        </Mui.Grid>
    )
}